<template>
  <div class="col-md-12">
    <b-card no-body>
      <div>
        <!-- table show more options -->
        <vue-accordion class="styled-accordion styled-accordion-dash u-border" animatedClass="animated-wrapper">
          <vue-accordion-item class="activeClass u-mt-xsmall u-inline">
            <a class="primary-link link" id="toggleMoreOption" slot="title" @click="toggle" ref="toggleMoreOptions">
              <span v-if="isClose === true" class="u-text-bold"> <i class="fa fa-plus u-text-small"></i> More options </span>
              <span v-if="isClose === false" class="u-text-bold"> <i class="fa fa-minus u-text-small"></i> hide options </span>
            </a>

            <div slot="content" class="item-content">
              <div class="col-md-12 u-pl-zero">
                <div class="row p-2">
                  <div class="ss--settings-module col-md-3 u-mb-xsmall">
                    <span class="c-toolbar__state-title">Choose an Action</span>
                    <div class="c-field">
                      <v-select v-model="selectedAction" :options="actionList" :value="selectedActionId" @input="onActionSelected" code="id" label="displayName"></v-select>
                    </div>
                  </div>

                  <!-- Dispatching Functionality -->
                  <div v-if="this.isDispatching" class="ss--settings-module col-md-3 u-mb-xsmall">
                    <span class="c-toolbar__state-title">Choose a Technician</span>
                    <div class="c-field">
                      <v-select v-model="selectedTechnician" :options="technicianList" :value="selectedActionId" @input="onTechnicianSelected" code="id" label="displayName"></v-select>
                    </div>
                  </div>
                  <div v-if="this.isDispatching" class="ss--settings-module col-md-2 u-mb-xsmall">
                    <span class="c-toolbar__state-title">Dispatch Date</span>
                    <div class="c-field">
                      <date-picker name="txtDispatchDate" v-model="dispatchDate" format="DD-MMM-YYYY" value-type="format" style="width: 100%;" lang="en" placeholder></date-picker>
                    </div>
                  </div>
                  <div v-if="this.isDispatching" class="ss--settings-module col-md-2 u-mb-xsmall">
                    <span class="c-toolbar__state-title u-text-capitalize">
                      <br />
                    </span>
                    <div class="c-field">
                      <button @click="dispatchTechnicianAction" class="btn ss-info-bg ss-white-font-color u-border-zero u-width-100" href="#!">
                        <span class="spanSave"> <i class="fa fa-truck u-mr-xsmall ss-white-font-color"></i>Dispatch </span>
                        <span class="spanLoading">
                          <div class="spinner-grow"></div>
                          Dispatching...
                        </span>
                      </button>
                      <span class="text-success" v-show="dispatchResponseVisible">{{ this.displayDispatchResponse }}</span>
                    </div>
                  </div>
                  <!-- //END Dispatching Functionality -->

                  <!-- Export Functionality -->
                  <div v-if="this.isExporting" class="ss--settings-module col-md-2 u-mb-xsmall">
                    <span class="c-toolbar__state-title u-text-capitalize">
                      <br />
                    </span>
                    <div class="c-field">
                      <button @click="onDownloadFile" class="btn ss-info-bg ss-white-font-color u-border-zero u-width-100" href="#!">
                        <span class="spanSave"> <i class="fa fa-file u-mr-xsmall ss-white-font-color"></i>Download File </span>
                      </button>
                    </div>
                  </div>
                  <div v-if="this.isExporting" class="ss--settings-module col-md- u-mb-xsmall">
                    <i v-if="this.isExportingStarted" class="fa fa-cog fa-spin fa-3x fa-fw" style="font-size:1.8em; margin-top:30px"></i>
                  </div>
                  <div v-if="this.isExportingStarted" class="ss--settings-module col-md-2 u-mb-xsmall" style="margin-top:30px;">{{ this.exportFileResponse }}</div>
                  <!-- //END Export Functionality -->
                </div>
                <!-- end table -->
              </div>
              <!-- col-md-12 -->
            </div>
          </vue-accordion-item>
        </vue-accordion>
      </div>
      <div class="table-responsive table-responsive-md w-auto dashboard-table">
        <table class="table" id="datatable">
          <thead class="c-table__head c-table__head--slim">
            <tr class="c-table__row">
              <th class="c-table__cell--head no-sort table-checkbox w-50px">
                <div class="c-choice c-choice--checkbox mb-0">
                  <input class="c-choice__input" id="tablecheck1" name="checkboxes" type="checkbox" />
                  <label class="c-choice__label" for="tablecheck1"></label>
                </div>
              </th>
              <th class="c-table__cell--head no-sort align-middle w-50px"></th>
              <th class="c-table__cell c-table__cell--head no-sort">Menu</th>
              <th class="c-table__cell c-table__cell--head no-sort">
                <a href="#">Status/Priority</a>
              </th>
              <th class="c-table__cell c-table__cell--head no-sort">
                <a href="#">Due Date</a>
              </th>
              <th class="c-table__cell c-table__cell--head no-sort">
                <a href="#">Technician Status</a>
              </th>
              <th class="c-table__cell c-table__cell--head no-sort"><span class="fa fa-arrow-up u-mr-xsmall clickable"></span>Job #</th>
              <th class="c-table__cell c-table__cell--head no-sort"><span class="fa fa-arrow-down u-mr-xsmall clickable"></span>Name</th>

              <th class="c-table__cell c-table__cell--head no-sort">Completed</th>
              <th class="c-table__cell c-table__cell--head no-sort">Created</th>
            </tr>
          </thead>
          <tbody>
            <tr class="c-table__row" v-for="n in 10" :key="n">
              <td class="align-middle table-checkbox">
                <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                  <input class="c-choice__input" id="1" name="checkboxes" type="checkbox" />
                  <label class="c-choice__label" for="1"></label>
                </div>
              </td>
              <td class="align-middle">
                <a class="edit-link" target="_blank" href="#">Edit</a>
              </td>
              <td class="text-left menu-icons pl-2">
                <i class="fa fa-truck u-mr-xsmall ss-primary-font-color"></i>
                <i class="fa fa-edit u-mr-xsmall ss-primary-font-color" id="abc">
                  <b-tooltip target="abc" triggers="hover" placement="righttop">
                    <div>Description + Notes</div>
                    <p>No description/notes are available.</p>
                  </b-tooltip>
                </i>
              </td>

              <td class="align-middle">description</td>
              <td class="align-middle">description</td>
              <td class="align-middle">description</td>
              <td class="text-nowrap align-middle">123</td>
              <td class="align-middle">
                <span style="font-weight:500;">description</span>
                <br />
                <span style="font-weight:200">description</span>
              </td>
              <td class="align-middle">description</td>
              <td class="align-middle">
                or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is
                thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
  import {VueAccordion, VueAccordionItem} from "vue-accordion-styless";
  export default {
    data() {
      return {
        dispatchDate: new Date().toISOString().slice(0, 10),
        isDispatching: false,
        isExportingStarted: false,
        downloadLink: "",
        isExporting: false,
        exportFileResponse: "",
        dispatchResponseVisible: false,
        displayDispatchResponse: "",
        selectedTechnician: null,
        selectedAction: null,
        actionList: [
          {id: 0, displayName: "Dispatch Technician"},
          {id: 1, displayName: "Export to Excel"},
        ],
        selectedActionId: 0,
        technicianList: [],
        isClose: true,
      };
    },
    methods: {
      toggle() {
        this.isClose = !this.isClose;
      },
    },
    components: {VueAccordion, VueAccordionItem},
  };
</script>
